<template>
  <div id="sessioninfo" class="sessioninfo container-fluid secure">
    <h1 class="page-header primary">API Session Info</h1>
    <!-- <span @click="copySupportInfoToClipboard" class="link blue underline-hover hover-dark-blue">Copy to Clipboard</span> -->
    <p class="f3 fw5 tracked">Current Session (this web instance)</p>

    <div class="table-responsive table--no-card m-b-40">
      <table class="table table-borderless table-striped">
        <tbody>
          <tr>
            <td>URL</td>
            <td>{{ sessionInfo.apiURL }}</td>
          </tr>
          <tr>
            <td>User logged in</td>
            <td>{{ sessionInfo.username }}</td>
          </tr>
          <tr>
            <td>Token Type</td>
            <td>
              {{ sessionInfo.token_type }}
            </td>
          </tr>
          <tr>
            <td>Token Valid For</td>
            <td>{{ sessionInfo.expires_in / 3600 }} hrs</td>
          </tr>
          <tr>
            <td>Token Expires At</td>
            <td>{{ expireDate }}</td>
          </tr>
          <tr>
            <td>Token Time Remaining</td>
            <td>{{ expireTimeLeft }}</td>
          </tr>
          <tr>
            <td>Service Build ID</td>
            <td>{{ getBuildInfo }}</td>
          </tr>
          <tr>
            <td>ACX Admin UI Build ID</td>
            <td>{{ getVersion }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <p class="f3 fw5 tracked">
      Revoke Session Tokens (for user: {{ getSessionObj.username }})
    </p>

    <button
      class="f6 dim primary b bg-white center ph3 pv2 mb2 br3"
      type="button"
      data-toggle="confirmation"
      @click="resetTokensConfirmFirst()"
    >
      Log me out of all sessions
    </button>

    <b-modal id="logout-modal" ref="modal" @ok="resetTokens">
      <template v-slot:modal-title>
        Invalidate all tokens for user:
        <span class="blue">
          {{ getSessionObj.username }}
        </span>
      </template>
      <span class="">
        This will log you out of this website AND all desktop and mobile
        connections to this API service.
      </span>
      <div class="b">
        Are you sure you want to do this?
      </div>
    </b-modal>
  </div>
</template>

<script>
import Helpers from '@/helpers.js';
import { mapGetters } from 'vuex';

export default {
  name: 'SessionInfo',
  data() {
    return {
      copyText: 'nothing copied',
      expireDate: '',
      expireTimeLeft: '',
    };
  },
  computed: {
    ...mapGetters(['getVersion', 'getBuildInfo', 'getSessionObj']),
    sessionInfo() {
      return this.getSessionObj;
    },
  },
  methods: {
    //   copySupportInfoToClipboard() {

    //     this.copyText = "URL: " + this.$parent.sessionInfo.apiURL + '\n';
    //     this.copyText += "User logged in: " + this.$parent.sessionInfo.username + '\n';
    //     this.copyText += "Token type: " + this.$parent.sessionInfo.token_type + '\n';
    //     this.copyText += "Token valid for: " + this.$parent.sessionInfo.expires_in / 3600 + '\n';

    //     this.$copyText(this.copyText).then(function (e) {
    //         // e is an object, e.text contains what was copied
    //         console.log(e)
    //         console.log(this)
    //         this.setAlertText("Hello")
    //         //return "Support info copied to clipboard, " + e.text;

    //     }, function (e) {
    //         console.log(e)
    //        // return "Error copying to clipboard";
    //     })

    //   }, // end copySupportInfoToClipboard
    calculateExpireDate() {
      let d = new Date(this.getSessionObj.tokenPayload.exp * 1000);
      d = Helpers.formatToLocalTime(d);
      //this.expireDate = d.getFullYear() + "/" + (d.getMonth()+1) + "/" + d.getDate() + " " + d.toTimeString();
      this.expireDate =
        d.getFullYear() +
        '/' +
        (d.getMonth() + 1) +
        '/' +
        d.getDate() +
        ' ' +
        Helpers.formatAMPM(d);
      return this.expireDate;
    }, // end expireDate
    calculateExpireTimeLeft() {
      let now = new Date();
      let then = new Date(this.expireDate);
      if (now > then) {
        // Token is expired.
        Helpers.logout(Helpers.errorMsgTokenExpired());
        this.expireTimeLeft = 'Token is expired!';
      } else {
        let timeLeft = then.getTime() - now.getTime();
        this.expireTimeLeft = Helpers.secondsToHms(timeLeft);
      }
      return this.expireTimeLeft;
    },
    resetTokensConfirmFirst() {
      this.$bvModal.show('logout-modal');
    },
    resetTokens() {
      if (Helpers.isTokenExpired(this.getSessionObj.tokenPayload.exp)) {
        Helpers.logout(
          'Your current session expired BEFORE invaliding tokens. Login and try again.'
        );
        return;
      }

      let url = this.getSessionObj.apiURL + '/logout';
      this.$http
        .post(
          url,
          {},
          { headers: { authorization: this.getSessionObj.access_token } }
        )
        .then(
          function(response) {
            console.log('Successfully invalidated tokens: ', response.body);
            Helpers.logout('Tokens invalidated, you are now logged out.');
          },
          response => {
            // Fail
            console.log('Failed POST to /users', response.body);
            if (response.body) {
              this.alert = 'Error: ' + response.bodyText;
              Helpers.showAlert('Error: ' + response.bodyText);
            } else {
              Helpers.showAlert('Failed to post to /users');
            }
          }
        );
    }, // end resetTokens
  }, // end methods

  created: function() {
    this.calculateExpireDate();
    this.calculateExpireTimeLeft();
    setInterval(this.calculateExpireTimeLeft, 1000);
  },
  components: {},
}; // end export default
</script>

<style scoped></style>
